@media only screen and (max-width: 767px) {
  .container {
  }
  .cta {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .headline {
    width: 100%;
  }
  .subtitle {
    width: 100%;
    margin-top: 0.75rem;
    margin-right: -1rem;
  }
  .card {
    margin-top: 3.125rem;
    border-radius: 1.25rem;
    padding: 3rem 1.25rem;
    padding-bottom: 1rem;
    background-color: var(--color-brand-white);
    display: flex;
    flex-direction: column;
    row-gap: 1.7rem;
  }
  .card__case {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 1.875rem;
    border-bottom: 0.063rem solid var(--color-illustrations-light-gray);
  }
  .card__case:last-child {
    border: none;
  }
  .card__case__catch {
    width: 100%;
    margin-right: 0;
    flex-shrink: 0;
  }
  .card__case__subtitle {
    display: none;
    margin-top: 0.75rem;
  }

  .card__case__catch-title {
    width: 90%;
  }

  .button {
    --button-border-color: var(--color-button-tertiary-bg);
    --button-color: var(--button-outline-text-black);
    --button-hover-opacity: 1;
    --button-hover-color: var(--color-brand-white);
    --button-hover-background-color: var(--color-illustrations-black-bg);
    margin-top: 1.5rem;
    height: 2.615rem;
    width: 100%;
    --button-font-size: 0.938rem;
    --button-line-height: 1.063rem;
    --button-icon-width: 0.6rem;
    --button-icon-height: 0.6rem;
  }

  .button[data-single="true"] {
    margin-top: 1.5rem;
  }
  .card__case__description {
    margin-top: 2rem;
  }

  .card__case__links {
    margin-top: 2rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 1.125rem;
    font-size: 1rem;
    line-height: 1.25rem;
    width: 100%;
    color: var(--color-text-description);
    --link-color: var(--color-text-description);
  }

  .card__case__links-item {
    cursor: text;
    width: 100%;
  }

  .card__case__links-link {
    pointer-events: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card__case__links-link-icon {
    opacity: 0;
  }
  .card__case__links-link:hover {
    opacity: 1;
    color: var(--color-text-accent);
  }
  .card__case__links-link:hover .card__case__links-link-icon {
    opacity: 1;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .container {
  }
  .cta {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .headline {
    width: 30%;
  }
  .subtitle {
    width: 75%;
    margin-top: 1.25rem;
    margin-right: -1rem;
  }
  .card {
    margin-top: 3.5rem;
    border-radius: 1.25rem;
    padding: 4.75rem 2.5rem;
    padding-bottom: 2.5rem;
    background-color: var(--color-brand-white);
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
  .card__case {
    display: flex;
    align-items: flex-start;
    padding-bottom: 2.5rem;
    border-bottom: 0.063rem solid var(--color-illustrations-light-gray);
  }
  .card__case:last-child {
    border: none;
  }
  .card__case__catch {
    width: 39%;
    margin-right: 4.5rem;
    flex-shrink: 0;
  }
  .card__case__subtitle {
    display: none;
    margin-top: 0.75rem;
  }

  .button {
    --button-border-color: var(--color-button-tertiary-bg);
    --button-color: var(--button-outline-text-black);
    --button-hover-opacity: 1;
    --button-hover-color: var(--color-brand-white);
    --button-hover-background-color: var(--color-illustrations-black-bg);
    height: 3.375rem;
    width: 11.063rem;
    margin-top: 3.5rem;
    --button-icon-width: 0.8rem;
    --button-icon-height: 0.8rem;
  }

  .button[data-single="true"] {
    margin-top: 2.5rem;
  }
  .card__case__description {
    margin-top: 0.5rem;
  }

  .card__case__links {
    margin-top: 0.5rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    font-size: 1.125rem;
    line-height: 1.625rem;
    width: 100%;
    color: var(--color-text-description);
    --link-color: var(--color-text-description);
  }

  .card__case__links-item {
    cursor: text;
    width: 100%;
  }

  .card__case__links-link {
    pointer-events: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card__case__links-link-icon {
    opacity: 0;
  }
  .card__case__links-link:hover {
    opacity: 1;
    color: var(--color-text-accent);
  }
  .card__case__links-link:hover .card__case__links-link-icon {
    opacity: 1;
  }
}
@media only screen and (min-width: 1440px) {
  .container {
  }
  .cta {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .headline {
    width: 30%;
  }
  .subtitle {
    width: 50%;
    margin-top: 0.75rem;
    margin-right: -1rem;
  }
  .card {
    margin-top: 3.5rem;
    border-radius: 1.25rem;
    padding: 4.75rem 2.5rem;
    padding-bottom: 2.5rem;
    background-color: var(--color-brand-white);
    display: flex;
    flex-direction: column;
    row-gap: 2.15rem;
  }
  .card__case {
    display: flex;
    align-items: flex-start;
    padding-bottom: 2.5rem;
    border-bottom: 0.063rem solid var(--color-illustrations-light-gray);
  }
  .card__case:last-child {
    border: none;
  }
  .card__case__catch {
    width: 39%;
    margin-right: 8rem;
    flex-shrink: 0;
  }
  .card__case__subtitle {
    margin-top: 0.75rem;
  }

  .button {
    --button-border-color: var(--color-button-tertiary-bg);
    --button-color: var(--button-outline-text-black);
    --button-hover-opacity: 1;
    --button-hover-color: var(--color-brand-white);
    --button-hover-background-color: var(--color-illustrations-black-bg);
    height: 3.375rem;
    width: 11.063rem;
    margin-top: 1.875rem;
    --button-icon-width: 0.8rem;
    --button-icon-height: 0.8rem;
  }

  .button[data-single="true"] {
    margin-top: 1.5rem;
  }
  .card__case__description {
    margin-top: 0.25rem;
  }

  .card__case__links {
    margin-top: 0.25rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    font-size: 1.125rem;
    line-height: 1.625rem;
    width: 100%;
    color: var(--color-text-description);
    --link-color: var(--color-text-description);
  }

  .card__case__links-item {
    cursor: text;
    width: 100%;
  }

  .card__case__links-link {
    pointer-events: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card__case__links-link-icon {
    opacity: 0;
  }
  .card__case__links-link:hover {
    opacity: 1;
    color: var(--color-text-accent);
  }
  .card__case__links-link:hover .card__case__links-link-icon {
    opacity: 1;
  }
}
