.AccordionPrimitiveTrigger {
    border-radius: var(--radius);
    background-color: transparent;
    color: hsl(var(--foreground));
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-size: 1rem;
    white-space: no-wrap;
    border: none;
  } 
  
  @media (max-width: 768px) {
    .AccordionPrimitiveTrigger {
      min-width: 100%;
      width: 100%;
    }
  }
  
  .AccordionPrimitiveTrigger:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .AccordionItem {
    border-bottom: 1px solid hsl(var(--border));
  }
  
  /* for accordion */
  @keyframes accordian-down {
    from {
      height: 0;
    } to {
      height: calc(var(--radix-accordion-content-height) + 0.55rem);
    }
  }
  
  @keyframes accordian-up {
    from {
      height: calc(var(--radix-accordion-content-height) + 0.55rem);
    } to {
      height: 0;
    }
  }
  
  .AccordionPrimitiveContent {
    color: hsl(var(--foreground) / 0.70);
    padding: 0;
    overflow: hidden; 
    font-size: 0.875rem;
  }
  
   .AccordionPrimitiveContent[data-state="open"] {
      animation: accordian-down 0.3s forwards;
    }
  
  .AccordionPrimitiveContent[data-state="closed"] {
      animation: accordian-up 0.3s forwards; 
  }
  
  .ChevronDownIcon {
    height: 1rem; 
    width: 1rem; 
    flex-shrink: 0;
    color: var(--muted-foreground);
    transition-property: transform; 
    transition-duration: 0.2s;
  }
  
  .AccordionContentDiv {
    padding: 0;
  }