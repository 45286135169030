.Checkbox {
  height: 1.25rem;
  width: 1.25rem;
  flex-shrink: 0;
  border-radius: 0.125rem;
  background-color: var(--background);
  border: 1px solid var(--border);
  border-radius: 0.375rem;
  outline: none;
  cursor: pointer;
  padding: 0;
}

.Checkbox:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.Checkbox[data-state="checked"] {
  background-color: var(--foreground);
  color: var(--background);
}

.CheckboxIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--check-color);
}

.Checkbox:is(:focus, :focus-visible) {
  outline: 1px solid var(--color-text-secondary);
  outline-offset: 2px;
}
