@media only screen and (max-width: 767px) {
  .main {
    margin-top: 1.125rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    overflow-x: hidden;
  }
  .breadcrumps {
    margin-top: 1.75rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .headline {
    font-size: 1.688rem;
    line-height: 2.188rem;
  }
  .subtitle {
    display: none;
  }
  .subtitle_mobile {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }
  .cta {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    flex-basis: 100%;
  }

  .button {
    margin-top: 0.5rem;
    width: 100%;
    height: 2.625rem;
    --button-font-size: 0.938rem;
    --button-line-height: 1.063rem;
    --button-padding-left: 0;
    --button-padding-right: 0;
    --button-padding-top: 0;
    --button-padding-bottom: 0;
    --button-icon-width: 0.5rem;
    --button-icon-height: 0.5rem;
  }
  .graphics {
    display: none;
  }

  .graphics__circle {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .graphics__circle__inner {
    border: 1.3px solid #f23b67;
    background: linear-gradient(
      0deg,
      rgba(242, 59, 103, 0.32) 0%,
      rgba(140, 34, 60, 0) 100%
    );
    width: 55%;
    height: 55%;
  }

  .graphics__circle__middle {
    background: linear-gradient(
      0deg,
      rgba(242, 59, 103, 0.15) 0%,
      rgba(140, 34, 60, 0) 100%
    );

    width: 80%;
    height: 80%;
  }

  .graphics__circle__outer {
    background: linear-gradient(
      0deg,
      rgba(242, 59, 103, 0.1) 0%,
      rgba(140, 34, 60, 0) 100%
    );
    width: 100%;
    height: 100%;
  }

  .graphics__header {
    font-size: 3.25rem;
    line-height: 3.875rem;
    z-index: 1;
  }
  .graphics__subheader {
    width: 30%;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.5rem;
    z-index: 1;
  }

  .cases {
    margin-top: 3.125rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.875rem;
  }

  .filter {
    width: 100%;
  }
  .filter__list {
    list-style: none;
    display: flex;
    column-gap: 0.625rem;
  }

  .filter__button {
    border-radius: 0.375rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    border: 0.063rem solid var(--button-border-color, #fff);

    font-size: 0.938rem;
    line-height: 1.063rem;
    font-weight: 400;

    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    background-color: transparent;
    outline: none;
    color: var(--button-color, #fff);

    width: auto;
    height: auto;
  }

  .filter__button:hover {
    cursor: pointer;
    color: var(--button-hover-color, inherit);
    opacity: var(--button-hover-opacity, 0.7);
    background-color: var(--button-hover-background-color, transparent);
  }

  .filter__button:active {
    opacity: var(--button-active-opacity, 1);
    opacity: var(--button-active-background-color, transparent);
  }

  .filter__button:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px var(--color-brand-white);
  }

  .filter__button:disabled {
    pointer-events: none;
    opacity: var(--button-hover-opacity, 0.5);
  }

  .filter__button:hover {
    opacity: var(--button-hover-opacity, 1);
    background: var(--button-hover-background-color, var(--color-brand-white));
    color: var(--button-color, var(--color-illustrations-black-bg));
  }

  .filter__button[data-state="active"] {
    background-color: var(--color-brand-white);
    color: var(--color-illustrations-black-bg);
  }

  .case {
    width: 100%;
  }

  .card {
    background: transparent;
    border-radius: 1rem;
    height: 12.5rem;
    overflow: hidden;
    position: relative;
  }
  .card-image {
    object-fit: cover;
    opacity: 0.6;
  }
  .card:hover .card-hover {
    display: flex;
  }
  .card-hover {
    background: var(--gradient-pink-purple-default);
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    column-gap: 1rem;
    top: 0;
  }
  .card-hover__content {
    display: grid;
    grid-template-columns: min-content minmax(0, 2fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .card-hover__content__single {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-hover__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 1.75rem;
  }
  .card-hover__text-small {
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: -1rem;
  }
  .card-hover__text-big {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    font-size: 2.375rem;
    margin-right: 1.25rem;
    text-wrap: nowrap;
  }
  .card-hover__button {
    --button-background-color: var(--icon-button-default-background);
    bottom: 1.25rem;
    height: 3.125rem;
    position: absolute;
    right: 1.25rem;
    width: 3.125rem;
    --icon-height: 1rem;
    --icon-width: 1rem;
  }

  .badge {
    backdrop-filter: blur(4px);
    background: var(--color-button-tertiary-bg-transparent);
    border-radius: 3.75rem;
    font-size: 0.875rem;
    left: 1.25rem;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    top: 1.25rem;
    width: fit-content;
  }
  .case__headline {
    margin-top: 1rem;
  }
  .case__description {
    margin-top: 0.5rem;
  }

  .cta__button {
    line-height: 1.063rem;
    height: 3.25rem;
    width: 17.575rem;
    margin-bottom: 0.6rem;
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;
  }

  .cases__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 1.75rem;
    row-gap: 3.75rem;
  }

  .pagination {
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.25rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .main {
    margin-top: 1.125rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    overflow-x: hidden;
  }
  .breadcrumps {
    margin-top: 3.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .subtitle_mobile {
    display: none;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .cta {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    flex-basis: 80%;
  }

  .button {
    width: 17.625rem;
    height: 3.25rem;
    --button-padding-left: 0;
    --button-padding-right: 0;
    --button-padding-top: 0;
    --button-padding-bottom: 0;
    --button-icon-width: 0.8rem;
    --button-icon-height: 0.8rem;
  }
  .graphics {
    display: none;
  }

  .graphics__circle {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .graphics__circle__inner {
    border: 1.3px solid #f23b67;
    background: linear-gradient(
      0deg,
      rgba(242, 59, 103, 0.32) 0%,
      rgba(140, 34, 60, 0) 100%
    );
    width: 55%;
    height: 55%;
  }

  .graphics__circle__middle {
    background: linear-gradient(
      0deg,
      rgba(242, 59, 103, 0.15) 0%,
      rgba(140, 34, 60, 0) 100%
    );

    width: 80%;
    height: 80%;
  }

  .graphics__circle__outer {
    background: linear-gradient(
      0deg,
      rgba(242, 59, 103, 0.1) 0%,
      rgba(140, 34, 60, 0) 100%
    );
    width: 100%;
    height: 100%;
  }

  .graphics__header {
    font-size: 3.25rem;
    line-height: 3.875rem;
    z-index: 1;
  }
  .graphics__subheader {
    width: 30%;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.5rem;
    z-index: 1;
  }

  .cases {
    margin-top: 3.125rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.875rem;
  }

  .filter {
    width: 100%;
  }
  .filter__list {
    list-style: none;
    display: flex;
    column-gap: 1.125rem;
  }

  .filter__button {
    border-radius: 0.375rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border: 0.063rem solid var(--button-border-color, #fff);

    font-size: 1rem;
    line-height: 1rem;
    font-weight: 400;

    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    background-color: transparent;
    outline: none;
    color: var(--button-color, #fff);

    width: auto;
    height: auto;
  }

  .filter__button:hover {
    cursor: pointer;
    color: var(--button-hover-color, inherit);
    opacity: var(--button-hover-opacity, 0.7);
    background-color: var(--button-hover-background-color, transparent);
  }

  .filter__button:active {
    opacity: var(--button-active-opacity, 1);
    opacity: var(--button-active-background-color, transparent);
  }

  .filter__button:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px var(--color-brand-white);
  }

  .filter__button:disabled {
    pointer-events: none;
    opacity: var(--button-hover-opacity, 0.5);
  }

  .filter__button:hover {
    opacity: var(--button-hover-opacity, 1);
    background: var(--button-hover-background-color, var(--color-brand-white));
    color: var(--button-color, var(--color-illustrations-black-bg));
  }

  .filter__button[data-state="active"] {
    background-color: var(--color-brand-white);
    color: var(--color-illustrations-black-bg);
  }

  .card {
    background: transparent;
    border-radius: 1rem;
    height: 12.5rem;
    overflow: hidden;
    position: relative;
  }
  .card-image {
    object-fit: cover;
    opacity: 0.6;
  }
  .card:hover .card-hover {
    display: flex;
  }
  .card-hover {
    background: var(--gradient-pink-purple-default);
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    column-gap: 1rem;
    top: 0;
  }
  .card-hover__content {
    display: grid;
    grid-template-columns: min-content minmax(0, 2fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .card-hover__content__single {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-hover__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 1.75rem;
  }
  .card-hover__text-small {
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: -1rem;
  }
  .card-hover__text-big {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    font-size: 2.375rem;
    margin-right: 1.25rem;
    text-wrap: nowrap;
  }
  .card-hover__button {
    --button-background-color: var(--icon-button-default-background);
    bottom: 1.25rem;
    height: 3.125rem;
    position: absolute;
    right: 1.25rem;
    width: 3.125rem;
    --icon-height: 1rem;
    --icon-width: 1rem;
  }

  .badge {
    backdrop-filter: blur(4px);
    background: var(--color-button-tertiary-bg-transparent);
    border-radius: 3.75rem;
    font-size: 0.875rem;
    left: 1.25rem;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    top: 1.25rem;
    width: fit-content;
  }
  .case__headline {
    margin-top: 1.55rem;
  }
  .case__description {
    margin-top: 0.5rem;
  }

  .cta__button {
    line-height: 1.063rem;
    height: 3.25rem;
    width: 17.575rem;
    margin-bottom: 0.6rem;
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;
  }

  .cases__cards {
    display: grid;
    flex-wrap: wrap;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(20.5rem, 1fr));
    grid-template-rows: 1fr;
    grid-column-gap: 1.75rem;
    grid-row-gap: 3.75rem;
  }

  .pagination {
    margin-top: 3.25rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-width: 1440px) {
  .main {
    margin-top: 1rem;
    padding-left: 10.313rem;
    padding-right: 10.313rem;
  }
  .breadcrumps {
    margin-top: 3.5rem;
    padding-left: 10.313rem;
    padding-right: 10.313rem;
  }
  .subtitle_mobile {
    display: none;
  }
  .pagination {
    margin-top: 3.25rem;
    margin-left: auto;
    margin-right: auto;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }
  .cta {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    flex-basis: 50%;
  }
  .button {
    width: 17.625rem;
    height: 3.25rem;
    --button-padding-left: 0;
    --button-padding-right: 0;
    --button-padding-top: 0;
    --button-padding-bottom: 0;
    --button-icon-width: 0.8rem;
    --button-icon-height: 0.8rem;
  }
  .graphics {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 23.75rem;
    height: 23.75rem;
    position: relative;
    flex-shrink: 0;
    display: flex;
    margin-top: -2.25rem;
  }

  .graphics__circle {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .graphics__circle__inner {
    border: 1.3px solid #f23b67;
    background: linear-gradient(
      0deg,
      rgba(242, 59, 103, 0.32) 0%,
      rgba(140, 34, 60, 0) 100%
    );
    width: 55%;
    height: 55%;
  }

  .graphics__circle__middle {
    background: linear-gradient(
      0deg,
      rgba(242, 59, 103, 0.15) 0%,
      rgba(140, 34, 60, 0) 100%
    );

    width: 80%;
    height: 80%;
  }

  .graphics__circle__outer {
    background: linear-gradient(
      0deg,
      rgba(242, 59, 103, 0.1) 0%,
      rgba(140, 34, 60, 0) 100%
    );
    width: 100%;
    height: 100%;
  }

  .graphics__header {
    font-size: 3.25rem;
    line-height: 3.875rem;
    z-index: 1;
  }
  .graphics__subheader {
    width: 30%;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.5rem;
    z-index: 1;
  }

  .cases {
    margin-top: 3.125rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.875rem;
  }

  .filter {
    width: 100%;
  }
  .filter__list {
    list-style: none;
    display: flex;
    column-gap: 1.125rem;
  }

  .filter__button {
    border-radius: 0.375rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border: 0.063rem solid var(--button-border-color, #fff);

    font-size: 1rem;
    line-height: 1rem;
    font-weight: 400;

    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    background-color: transparent;
    outline: none;
    color: var(--button-color, #fff);

    width: auto;
    height: auto;
  }

  .filter__button:hover {
    cursor: pointer;
    color: var(--button-hover-color, inherit);
    opacity: var(--button-hover-opacity, 0.7);
    background-color: var(--button-hover-background-color, transparent);
  }

  .filter__button:active {
    opacity: var(--button-active-opacity, 1);
    opacity: var(--button-active-background-color, transparent);
  }

  .filter__button:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px var(--color-brand-white);
  }

  .filter__button:disabled {
    pointer-events: none;
    opacity: var(--button-hover-opacity, 0.5);
  }

  .filter__button:hover {
    opacity: var(--button-hover-opacity, 1);
    background: var(--button-hover-background-color, var(--color-brand-white));
    color: var(--button-color, var(--color-illustrations-black-bg));
  }

  .filter__button[data-state="active"] {
    background-color: var(--color-brand-white);
    color: var(--color-illustrations-black-bg);
  }

  .card {
    background: transparent;
    border-radius: 1rem;
    height: 18.75rem;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .card-image {
    object-fit: cover;
    opacity: 0.6;
  }
  .card:hover .card-hover {
    display: flex;
  }
  .card-hover {
    background: var(--gradient-pink-purple-default);
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    column-gap: 1rem;
    top: 0;
  }
  .card-hover__content {
    display: grid;
    grid-template-columns: min-content minmax(0, 3fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0.75rem;
    grid-row-gap: 1.25rem;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: -0.25rem;
  }
  .card-hover__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 1.75rem;
  }
  .card-hover__text-small {
    font-size: 1.5rem;
    line-height: 1.875rem;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .card-hover__content__single {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-hover__text-single {
    /* position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%); */
  }
  .card-hover__text-big {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.25rem;
    line-height: 3.875rem;
    text-wrap: nowrap;
  }
  .card-hover__button {
    bottom: 1.25rem;
    position: absolute;
    right: 2.375rem;

    --button-background-color: var(--icon-button-default-background);
    --button-height: 3.125rem;
    --button-width: 3.125rem;
    --button-icon-height: 1rem;
    --button-icon-width: 1rem;
  }

  .badge {
    backdrop-filter: blur(4px);
    background: var(--color-button-tertiary-bg-transparent);
    border-radius: 3.75rem;
    font-size: 0.875rem;
    left: 1.25rem;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    top: 1.25rem;
    width: fit-content;
  }
  .case__headline {
    margin-top: 1.5rem;
  }
  .case__description {
    margin-top: 0.5rem;
  }

  .cta__button {
    line-height: 1.063rem;
    height: 3.25rem;
    width: 17.575rem;
    margin-bottom: 0.6rem;
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;
  }

  .cases__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1.75rem;
    row-gap: 3.75rem;
  }
  .case {
    width: 27.938rem;
  }
  .cases__cards .case:nth-child(4n + 1) {
    width: 39.625rem;
  }
  .cases__cards .case:nth-child(4n + 4) {
    width: 39.625rem;
  }
}

.download__button a {
  --link-color: white;
  text-decoration: underline;
}
