@media only screen and (max-width: 767px) {
  .main {
    margin-top: 1.125rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    overflow-x: hidden;
  }
  .subtitle{
    max-width: 100%;
  }
  .cta {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-top: 1.125rem;
    flex-direction: column;
    row-gap: 1.85rem;
  }

  .breadcrumps {
    margin-top: 1.75rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .white-block {
    background-color: var(--color-light-purple-bg);
    border-radius: 1.25rem;
    color: var(--color-illustrations-black-bg);
    display: flex;
    flex-direction: column;
    margin-left: -1.125rem;
    margin-right: -1.125rem;
    margin-top: 5.125rem;
    padding: 4.5rem 1.125rem;
    padding-bottom: 5.125rem;
    row-gap: 5.75rem;
    --color-text-description: #555555;
    --color-text-main: var(--color-illustrations-black-bg);
    --color-text-subtitle: #3a3a3a;
  }

  .button {
    height: 2.615rem;
    width: 100%;
    --button-font-size: 0.938rem;
    --button-line-height: 1.063rem;
    --button-icon-width: 0.6rem;
    --button-icon-height: 0.6rem;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1439px) {
  .main {
    margin-top: 1.25rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    overflow-x: hidden;
  }
  .subtitle{
    max-width: 77%;
  }
  .cta {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
    flex-direction: column;
    row-gap: 1.75rem;
  }

  .breadcrumps {
    margin-top: 3.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .white-block {
    background-color: var(--color-light-purple-bg);
    border-radius: 2.5rem;
    color: var(--color-illustrations-black-bg);
    display: flex;
    flex-direction: column;
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    margin-top: 6.25rem;
    padding: 5.75rem 2.5rem;
    padding-bottom: 6.25rem;
    row-gap: 6.75rem;
    --color-text-description: #555555;
    --color-text-main: var(--color-illustrations-black-bg);
    --color-text-subtitle: #3a3a3a;
  }

  .button {
    height: 3.375rem;
    width: 17.625rem;
    --button-icon-width: 0.8rem;
    --button-icon-height: 0.8rem;
  }
}
@media only screen and (min-width: 1440px) {
  .main {
    margin-top: 1rem;
    padding-left: 10.313rem;
    padding-right: 10.313rem;
    overflow-x: hidden;
  }
  .subtitle{
    max-width: 47.8%;
  }
  .cta {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
  }

  .breadcrumps {
    margin-top: 3.5rem;
    padding-left: 10.313rem;
    padding-right: 10.313rem;
  }

  .white-block {
    background-color: var(--color-light-purple-bg);
    border-radius: 2.5rem;
    color: var(--color-illustrations-black-bg);
    display: flex;
    flex-direction: column;
    margin-left: -10.313rem;
    margin-right: -10.313rem;
    margin-top: 6.25rem;
    padding: 5.5rem 10.313rem;
    padding-bottom: 6.25rem;
    row-gap: 6.75rem;
    --color-text-description: #555555;
    --color-text-main: var(--color-illustrations-black-bg);
    --color-text-subtitle: #3a3a3a;
  }

  .button {
    height: 3.375rem;
    width: 17.625rem;
    --button-icon-width: 0.8rem;
    --button-icon-height: 0.8rem;
  }
}