.button {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  border-radius: 6.25rem;
  line-height: var(--button-line-height, 1.125rem);
  font-size: var(--button-font-size, 1.125rem);
  font-weight: 400;

  outline: none;
  background-color: transparent;

  color: var(--button-color, #fff);
  border: 0.094rem solid var(--button-border-color, #fff);

  padding-left: 1.625rem;
  padding-right: 1.625rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.button:hover {
  cursor: pointer;
  color: var(--button-hover-color, inherit);
  opacity: var(--button-hover-opacity, 0.7);
  background-color: var(--button-hover-background-color, transparent);
}

.button:active {
  opacity: var(--button-active-opacity, 1);
  opacity: var(--button-active-background-color, transparent);
}

.button:focus-visible {
  outline: none;
  box-shadow: 0 0 0 1px var(--color-brand-white);
}

.button:disabled {
  pointer-events: none;
  opacity: var(--button-hover-opacity, 0.5);
}

.button:disabled:focus-visible {
  outline: none;
  box-shadow: none;
}

.button[data-variant="primary"] {
  border: none;
  background: var(--gradient-pink-purple-default);
}

.button[data-variant="primary"]:hover {
  background: var(
    --button-hover-background-color,
    var(--gradient-pink-purple-hover)
  );
}
.button[data-variant="primary"]:active {
  background: var(--gradient-pink-purple-press);
}

.button[data-variant="tertiary"] {
  border: none;
  background-color: var(--color-button-tertiary-bg);
}

.button[data-variant="tertiary"]:hover {
  background: var(
    --button-hover-background-color,
    var(--color-button-tertiary-bg-hover)
  );
}

.button[data-variant="tertiary"]:active {
  background-color: var(--color-button-tertiary-bg-press);
}

.button[data-variant="outline"] {
  border: 0.094rem solid var(--button-border-color, #fff);
  background-color: transparent;
}

.button[data-variant="outline"]:hover {
  cursor: pointer;
  border-color: var(--button-border-color, #fff);
  background: var(
    --button-hover-background-color,
    var(--color-illustrations-black-primary)
  );
  opacity: var(--button-hover-opacity, 0.5);
}

.button[data-size="sm"] {
  font-size: 0.938rem;
  line-height: 1.063rem;
  padding-left: 1.625rem;
  padding-right: 1.625rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.right-element {
  margin-left: 0.547rem;
  --icon-width: var(--button-icon-width, 0.63rem);
  --icon-height: var(--button-icon-height, 0.63rem);
}
