
.DialogOverlay {
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 101;
}

.DialogContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: var(--color-text-main);
  --color-text: var(--color-illustrations-black-bg);
  border-radius: 0;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  bottom: 2rem;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
  height: fit-content;
  padding: 1.5rem;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 101;
  border-radius: 1rem;
  --font-size: 0.875rem;
  --line-height: 1rem;
  max-width: 45.563rem;
}

.button {
  width: 13.375rem;
  height: 2.625rem;
  --button-font-size: 0.938rem;
  --button-line-height: 1.063rem;
}
.message {
    max-width: 61%;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, 10%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0%) scale(1);
  }
}

@media only screen and (max-width: 767px) {
    .DialogContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        row-gap: 1.25rem;
        bottom: 0;
        max-width: 100%;
      }
      
      .message {
          max-width: 100%;
      }
  }