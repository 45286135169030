@media only screen and (max-width: 767px) {
  .desktop {
    display: none !important;
  }
  .non-desktop {
    display: flex !important;
  }
  .button__cta {
    display: none !important;
  }
  .dropdown-menu__item__desktop {
    display: none !important;
  }
  .header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .nav__trigger-icon-desktop {
    display: none;
  }
  .nav {
    background-color: var(--color-light-purple-bg);
    border-radius: 0.625rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    bottom: 0;
    color: var(--color-illustrations-black-bg);
    display: none;
    height: calc(100% - 5rem);
    margin-left: auto;
    overflow: hidden;
    padding: 1.25rem 1rem;
    position: absolute;
    right: 0;
    top: 5rem;
    transform: translateX(100%);
    transition: transform 0.1s ease;
    width: 100%;
    z-index: 101;
    overflow: hidden;
  }

  .nav[data-open-sub="true"] .nav__header {
    display: none;
  }

  .nav__list {
    display: flex;
    flex-direction: column;
    height: 100%;
    list-style-type: none;
    position: relative;
    overflow: auto;
  }
  .nav__item {
    align-items: center;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    line-height: 1rem;
    width: 100%;
    border-bottom: 0.063rem solid var(--color-illustrations-light-gray);
  }
  .nav__item:nth-of-type(1) {
    border-top: 0.063rem solid var(--color-illustrations-light-gray);
  }
  .nav__item:hover {
    cursor: pointer;
  }
  .phone {
    display: none;
    margin-left: auto;
  }
  .button {
    display: none !important;
    --button-border-color: var(--color-button-tertiary-bg);
    --button-color: var(--button-outline-text-black);
    --button-hover-color: var(--color-brand-white);
    --button-hover-opacity: 1;
    --button-hover-background-color: var(--color-illustrations-black-bg);
    margin-bottom: 2.5rem;
  }
  .mobile__phone {
    display: block;
    font-size: 0.938rem;
    line-height: 1.063rem;
    margin-bottom: 1rem;
  }
  .mobile__button {
    --button-border-color: var(--color-button-tertiary-bg);
    --button-color: var(--button-outline-text-black);
    --button-hover-color: var(--color-brand-white);
    --button-hover-opacity: 1;
    --button-hover-background-color: var(--color-illustrations-black-bg);
    margin-bottom: 2.75rem;
    width: 100%;
  }
  .icon__button {
    margin-left: 1rem;
  }
  .nav__item[data-open="true"] > .nav__list-content {
    display: block;
  }
  .nav__list-content {
    background-color: var(--color-light-purple-bg);
    border-bottom-left-radius: 0.625rem;
    border-radius: 0.625rem;
    bottom: 0;
    color: var(--color-illustrations-black-bg);
    cursor: default;
    display: none;
    left: -5rem;
    list-style: none;
    margin-left: auto;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: 0;
    width: 25rem;
    z-index: 2;
    font-size: 1rem;
    line-height: 1rem;
  }
  .dropdown-menu__item[data-open="true"] > .nav__list-sub-content {
    display: block;
  }
  .nav__list-sub-content {
    background-color: var(--color-light-purple-bg);
    border-bottom-left-radius: 0;
    border-radius: 0;
    border-top-left-radius: 0;
    color: var(--color-illustrations-black-bg);
    cursor: default;
    display: flex;
    flex-direction: column;
    left: 0;
    list-style: none;
    margin-top: 0.25rem;
    overflow: visible;
    padding: 0;
    position: relative;
    top: 0;
    width: auto;
    z-index: 1;
  }
  .nav__trigger {
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.25rem;
  }
  .nav__trigger-icon {
    --icon-width: 0.8rem;
    --icon-height: 0.8rem;
  }
  .dropdown-menu__sub-content {
    top: 0;
  }
  .dropdown-menu__item {
    align-items: center;
    border-radius: 0.375rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    font-size: 1rem;
    line-height: 1rem;
    height: 3.5rem;
  }
  .dropdown-menu__item > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 1.25rem 1.5rem;
  }
  .dropdown-menu__item > * {
    height: 100%;
    width: 100%;
  }
  .dropdown-menu__trigger {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .dropdown-menu__trigger-icon {
    --icon-width: 0.8rem;
    --icon-height: 0.8rem;
    transform: rotateX(0deg);
  }
  .dropdown-menu__back-link {
    font-size: 0.938rem;
    line-height: 1.063rem;
    align-items: center;
    --link-color: var(--color-text-accent);
    column-gap: 0.5rem;
    display: flex;
    width: fit-content;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
  }
  .dropdown-menu__back-icon {
    --icon-width: 0.8rem;
    --icon-height: 0.8rem;
  }
  .dropdown-menu__item[data-highlighted="true"],
  .dropdown-menu__item:hover {
    background: var(--select-active-background);
    border: none;
    outline: none;
  }
  .dropdown-menu-content-sub {
    border-top-left-radius: 0;
  }
  .menu__button {
    /* margin-left: 1.25rem; */
    margin-left: auto;
    display: flex;
  }
  .nav__item > * {
    height: 100%;
    width: 100%;
  }
  .nav__item > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 1.325rem 0.75rem;
  }
  .nav__item > a > svg {
    pointer-events: none;
    margin-right: 0.3rem;
  }
  .nav__item[data-open="true"],
  .nav__item:hover {
    background: var(--select-active-background);
    border: none;
    outline: none;
  }
  .nav[data-open="true"] {
    display: block;
    transform: translateX(0px);
  }
  .accordion-container {
    display: flex;
    flex-direction: column;
    row-gap: 0rem;
    margin-top: 0.125rem;
  }
  .accordion-trigger {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: space-between;
    outline: none;
    padding: 1rem;
    text-align: left;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    margin-left: -0.125rem;
  }
  .accordion-trigger:hover,
  .accordion-trigger[data-state="open"] {
    background: var(--select-active-background);
  }
  .accordion-trigger[data-state="open"] > .dropdown-menu__trigger-icon {
    transform: rotateX(180deg);
  }
  .dropdown-menu__item::after {
    background-color: var(--color-illustrations-light-gray);
    bottom: -0.06rem;
    content: "";
    height: 0.063rem;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
  }
  .user_icon {
    margin-left: auto;
    --icon-height: 1.5rem;
    --icon-width: 1.5rem;
  }
  .logo {
    height: 2.156rem;
    width: 8.063rem;
  }
  .logo__link {
    height: 2.156rem;
  }
  .logo__link[data-active="false"] {
    pointer-events: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .desktop {
    display: none !important;
  }
  .non-desktop {
    display: flex !important;
  }
  .header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .dropdown-menu__item__desktop {
    display: none !important;
  }
  .nav__header__mobile {
    display: none;
  }
  .nav__trigger-icon-desktop {
    display: none;
  }
  .nav {
    background-color: var(--color-light-purple-bg);
    border-radius: 0.625rem;
    border-top-right-radius: 0;
    bottom: 0;
    color: var(--color-illustrations-black-bg);
    display: none;
    height: calc(100% - 4.5rem);
    margin-left: auto;
    overflow: hidden;
    padding: 1.875rem 2.625rem;
    padding-right: 2rem;
    position: absolute;
    right: 0;
    top: 4.5rem;
    transform: translateX(100%);
    transition: transform 0.1s ease;
    border-bottom-left-radius: 0;
    width: 25rem;
    z-index: 101;
    overflow: hidden;
  }
  .nav__list {
    display: flex;
    flex-direction: column;
    height: 100%;
    list-style-type: none;
    position: relative;
    row-gap: 0.25rem;
    overflow: auto;
  }
  .nav__item {
    align-items: center;
    border-radius: 0.375rem;
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    line-height: 1rem;
    width: 100%;
  }
  .nav__item:hover {
    cursor: pointer;
  }
  .phone {
    display: none;
    margin-left: auto;
  }
  .button {
    margin-left: 1rem;
    margin-left: auto;
  }
  .icon__button {
    margin-left: 1rem;
  }
  .nav__item[data-open="true"] > .nav__list-content {
    display: block;
  }
  .nav__list-content {
    background-color: var(--color-light-purple-bg);
    border-bottom-left-radius: 0.625rem;
    border-radius: 0.625rem;
    bottom: 0;
    color: var(--color-illustrations-black-bg);
    cursor: default;
    display: none;
    left: -5rem;
    list-style: none;
    margin-left: auto;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: 0;
    width: 25rem;
    z-index: 101;
  }
  .dropdown-menu__item[data-open="true"] > .nav__list-sub-content {
    display: block;
  }
  .nav__list-sub-content {
    background-color: var(--color-light-purple-bg);
    border-bottom-left-radius: 0;
    border-radius: 0;
    border-top-left-radius: 0;
    color: var(--color-illustrations-black-bg);
    cursor: default;
    display: flex;
    flex-direction: column;
    left: 0;
    list-style: none;
    margin-top: 0.125rem;
    overflow: visible;
    padding: 0;
    position: relative;
    top: 0;
    width: auto;
    z-index: 101;
  }

  .nav__trigger {
    align-items: flex-end;
    column-gap: 0.5rem;
    display: flex;
    justify-content: space-between;
  }
  .nav__trigger-icon {
    display: none;
    --icon-height: 0.8rem;
    --icon-width: 0.8rem;
  }
  .dropdown-menu__sub-content {
    top: 0;
  }
  .dropdown-menu__item {
    align-items: center;
    border-radius: 0.375rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    font-size: 1rem;
    line-height: 1rem;
    height: 3.5rem;
  }

  .dropdown-menu__item > a {
    padding: 1.25rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 1.25rem 1.5rem;
  }
  .dropdown-menu__trigger {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .dropdown-menu__trigger-icon {
    --icon-height: 0.8rem;
    --icon-width: 0.8rem;
    transform: rotateX(0deg);
  }
  .dropdown-menu__back-link {
    font-size: 0.938rem;
    line-height: 1.063rem;
    align-items: center;
    --link-color: var(--color-text-accent);
    column-gap: 0.5rem;
    display: flex;
    justify-content: flex-start;
    width: fit-content;
    margin-bottom: 1.5rem;
  }
  .dropdown-menu__back-icon {
    --icon-height: 0.8rem;
    --icon-width: 0.8rem;
  }
  .dropdown-menu__item[data-highlighted="true"],
  .dropdown-menu__item:hover {
    background: var(--select-active-background);
    border: none;
    outline: none;
  }
  .dropdown-menu-content-sub {
    border-top-left-radius: 0;
  }
  .menu__button {
    display: flex;
  }
  .nav__item > * {
    height: 100%;
    width: 100%;
  }
  .nav__item > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 1rem;
  }
  .nav__item > a > svg {
    pointer-events: none;
  }
  .nav__item[data-open="true"],
  .nav__item:hover {
    background: var(--select-active-background);
    border: none;
    outline: none;
  }
  .nav[data-open="true"] {
    display: block;
    transform: translateX(0);
  }
  .accordion-container {
    display: flex;
    flex-direction: column;
    row-gap: 0rem;
    margin-top: 0.125rem;
  }
  .accordion-trigger {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: space-between;
    outline: none;
    padding: 1rem;
    text-align: left;
    width: 100%;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 400;
    margin-left: -0.125rem;
  }
  .accordion-trigger:hover,
  .accordion-trigger[data-state="open"] {
    background: var(--select-active-background);
  }
  .accordion-trigger[data-state="open"] > .dropdown-menu__trigger-icon {
    transform: rotateX(180deg);
  }
  .dropdown-menu__item::after {
    background-color: var(--color-illustrations-light-gray);
    bottom: -0.06rem;
    content: "";
    height: 0.063rem;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
  }

  .user_icon {
    --icon-height: 1.5rem;
    --icon-width: 1.5rem;
  }
}
@media only screen and (min-width: 1440px) {
  .desktop {
    display: inline-block !important;
  }
  .non-desktop {
    display: none !important;
  }
  .header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    --link-color: var(--color-text-main);
  }
  .accordion-container {
    display: none;
  }
  .logo__link {
    height: 2.625rem;
  }
  .logo__link[data-active="false"] {
    pointer-events: none;
  }
  .nav__header__mobile {
    display: none;
  }
  .nav {
    margin-left: 4rem;
  }
  .nav__list {
    column-gap: 0.938rem;
    display: flex;
    list-style-type: none;
    position: relative;
  }
  .nav__trigger-icon {
    --icon-height: 0.55rem;
    --icon-width: 0.55rem;
    margin-right: 0.3rem;
    margin-left: 0.35rem;
  }
  .nav__trigger {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
  }
  .nav__item {
    font-size: 0.875rem;
    line-height: 1rem;
  }
  .nav__item:hover {
    cursor: pointer;
  }
  .phone {
    font-size: 0.875rem;
    line-height: 1rem;
    margin-left: auto;
  }
  .button {
    margin-left: 1rem;
  }
  .icon__button {
    margin-left: 1rem;
  }
  .nav__item[data-open="true"] > .nav__list-content {
    display: flex;
    flex-direction: column;
    row-gap: 0.125rem;
  }
  .nav__list-content {
    background-color: var(--color-light-purple-bg);
    border-radius: 0.625rem;
    color: var(--color-illustrations-black-bg);
    --link-color: var(--color-illustrations-black-bg);
    cursor: default;
    display: none;
    left: -7.25rem;
    list-style: none;
    padding: 1.5rem;
    position: absolute;
    top: 3.275rem;
    width: 22rem;
    z-index: 101;
    font-size: 1rem;
    line-height: 1rem;
  }
  .dropdown-menu__item[data-open="true"] > .nav__list-sub-content {
    display: flex;
    flex-direction: column;
    row-gap: 0.15rem;
  }
  .dropdown-menu__trigger-icon-mobile {
    display: none;
  }
  .nav__list-sub-content {
    background-color: var(--color-light-purple-bg);
    border-radius: 0.625rem;
    border-top-left-radius: 0;
    color: var(--color-illustrations-black-bg);
    --link-color: var(--color-illustrations-black-bg);
    cursor: default;
    display: none;
    list-style: none;
    overflow: hidden;
    padding: 1.5rem;
    padding-top: 4.5rem !important;
    position: absolute;
    left: 21rem;
    top: 0;
    right: 0;
    z-index: 101;
  }
  .dropdown-menu__sub-content {
    top: 0;
  }
  .dropdown-menu__item {
    cursor: pointer;
    align-items: center;
    border-radius: 0.375rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 3.5rem;
    padding: 0 1.125rem;
  }
  .dropdown-menu__item > * {
    height: 100%;
    width: 100%;
  }
  .dropdown-menu__item > a {
    display: flex;
    align-items: center;
  }

  .dropdown-menu__trigger {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .dropdown-menu__trigger-icon {
    --icon-height: 0.8rem;
    --icon-width: 0.8rem;
  }
  .dropdown-menu__back {
    display: none;
  }
  .dropdown-menu__back-link {
    align-items: center;
    --link-color: var(--color-text-accent);
    display: flex;
    justify-content: flex-start;
    width: fit-content;
    column-gap: 0.3rem;
    margin-bottom: 1.875rem;
  }
  .dropdown-menu__back-icon {
    --icon-height: 1rem;
    --icon-width: 1rem;
  }
  .dropdown-menu__item[data-highlighted="true"],
  .dropdown-menu__item:hover {
    background: var(--select-active-background);
    border: none;
    outline: none;
  }
  .dropdown-menu-content-sub {
    border-top-left-radius: 0;
  }
  .menu__button {
    display: none !important;
  }

  .user_icon {
    --icon-height: 1.5rem;
    --icon-width: 1.5rem;
  }

  .logo {
    height: 2.625rem;
    width: 9.813rem;
  }
}
